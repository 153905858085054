import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Style} from 'mapbox-gl';
import {ArcGisStyle} from '../../model/arcgis-style';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TileJsonService {
    constructor(private http: HttpClient) {
    }

    async fetchArcgisTileJson(styleName: string): Promise<Style> {
        const styleBaseUrl = environment.arcgisBasemapStyleUrl;
        const style = await this.http.get<ArcGisStyle>(`${styleBaseUrl}/${styleName}?token=${environment.arcgisApiKey}`).toPromise();

        return {
            version: style.version,
            name: styleName,
            sprite: style.sprite,
            glyphs: style.glyphs,
            sources: style.sources,
            layers: style.layers
        };
    }
}
