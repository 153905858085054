import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiPageHeaderComponent} from './components/ui-page-header/ui-page-header.component';
import {RouterModule} from '@angular/router';
import { UiPageContentComponent } from './components/ui-page-content/ui-page-content.component';
import {VwuiAngularModule} from '@recognizebv/vwui-angular';
import { UiEmptyStateComponent } from './components/ui-empty-state/ui-empty-state.component';
import {InlineSVGModule} from 'ng-inline-svg-2';
import { UiSectionComponent } from './components/ui-section/ui-section.component';
import { UiFieldComponent } from './components/ui-field/ui-field.component';
import { UiInfoBoxComponent } from './components/ui-info-box/ui-info-box.component';
import { UiStatusLabelComponent } from './components/ui-status-label/ui-status-label.component';


@NgModule({
    declarations: [
        UiPageHeaderComponent,
        UiPageContentComponent,
        UiEmptyStateComponent,
        UiSectionComponent,
        UiFieldComponent,
        UiInfoBoxComponent,
        UiStatusLabelComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        VwuiAngularModule,
        InlineSVGModule
    ],
    exports: [
        UiPageHeaderComponent,
        UiPageContentComponent,
        VwuiAngularModule,
        UiEmptyStateComponent,
        UiSectionComponent,
        UiFieldComponent,
        UiInfoBoxComponent,
        UiStatusLabelComponent
    ]
})
export class UiModule {
}
